import React from "react";
import {Link} from "react-router-dom";

export default ({ url, name }) => (
    <Link onClick={()=>{
        setTimeout(function (){
            window.location.reload();
        }, 1000)
    }} to={url} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-green-50">
        {name}
    </Link>
);