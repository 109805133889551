import React from "react";
import {Link} from "react-router-dom";

import Snow from 'images/Anhyka-Photo.jpg';
import Image1 from 'images/gallery/gallery_13.jpg';
import Image2 from 'images/gallery/gallery_34.jpg';
import Image3 from 'images/gallery/gallery_32.jpg';
import Image4 from 'images/gallery/gallery_04.jpg';
import Image5 from 'images/gallery/gallery_09.jpg';
import Image6 from 'images/gallery/gallery_30.jpg';
import Image7 from 'images/gallery/gallery_01.jpg';

const incentives = [
    {
        name: 'Our Misson',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
        description: "My delight is your joy. Throughout the unfolding of your garden, from the initial, conceptual dream to installation, we will witness a wellspring of joy being nurtured. At the close of my days, I am increasingly aware that it is the component of human connection with the parallel aim of garden bliss that I find most nourishing. In the name of joy, let’s create!",
    },
]

class About extends React.Component {
    render(){
        return(
            <div className="bg-gray-50">
                <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
                    <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
                        <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
                            <div>
                                <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                                    About StoneLeaf Design
                                </h2>
                                <p className="mt-4 text-gray-500">
                                    I have gratefully been practicing this art since 2001, delighting in the deep passion
                                    derived from the various stages of the design process. Having lived in Boulder County
                                    for nearly a decade, I can attest to this terrain and its open-minded people as having
                                    provided endless opportunity for various forms of fulfilled expression.
                                    I continue to be inspired by the challenge of marrying aesthetic nuances with sheer
                                    utility in the outdoor setting and now invite you to explore the potential of your awaited,
                                    unique garden space with StoneLeaf Design.
                                </p>
                            </div>
                            <div className="aspect-w-3 aspect-h-3 bg-gray-100 rounded-lg overflow-hidden">
                                <img
                                    src={Snow}
                                    alt="Anhyka Photo"
                                    className="object-center object-cover"
                                />
                            </div>
                        </div>
                        <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-1">
                            {incentives.map((incentive) => (
                                <div key={incentive.name} className="sm:flex lg:block">
                                    <div className="sm:flex-shrink-0">
                                        <img className="w-16 h-16" src={incentive.imageSrc} alt="" />
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                        <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                                        <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="relative bg-white overflow-hidden">
                    <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
                            <div className="sm:max-w-lg">
                                <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                                    Creating a living space in your garden
                                </h1>
                            </div>
                            <div>
                                <div className="mt-10">
                                    {/* Decorative image grid */}
                                    <div
                                        aria-hidden="true"
                                        className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                                    >
                                        <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                            <div className="flex items-center space-x-6 lg:space-x-8">
                                                <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                                                        <img
                                                            src={Image1}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={Image2}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={Image3}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={Image4}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={Image5}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={Image6}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                    <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                        <img
                                                            src={Image7}
                                                            alt=""
                                                            className="w-full h-full object-center object-cover"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Link
                                        to="/contact-us"
                                        className="inline-block text-center bg-indigo-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-indigo-700"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default About;