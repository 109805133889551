import React from "react";
import {Link} from "react-router-dom";

import Eleuterio from 'images/contractors/eleuterio.jpg';
import Satoro from 'images/contractors/satoro.jpg';

const contractors = [
    {
        name: 'Eleuterio',
        role: 'Monte Vista Landscapes',
        imageUrl: Eleuterio,
        bio: 'StoneLeaf Design turns first to Monte Vista Landscapes for the overall installation of its garden designs. Eleuterio (Telo) and Anhyka have been in a work relationship since 2005 and have each witnessed the other\'s growth in their respective fields. StoneLeaf Design appreciates the quantity of check-ins that Monte Vista performs throughout the entire installation process, ensuring that the intention of the design becomes a felt experience by its garden users. StoneLeaf Design is confident when proposing Monte Vista Landscapes, knowing that its client is going to receive a top-notch installation.',
        website: 'https://montevistalandscapes.com'
    },
    {
        name: 'SaToro Tafura',
        role: 'Stone Sculptor',
        imageUrl: Satoro,
        bio: 'Tafura is from a famous sculpting family in Zimbabwe and is the son of renown sculptor, Agnes Nyanhongo. By sculpting almost exclusively with hand tools, Tafura maintains an intimate connection with each stone. More than a decade ago, Tafura’s sculpture began to attract international interest. He has been an Artist in Residence with Chapungu Sculpture Park and has exhibited in Germany, England, and throughout the U.S. He’s excited about his most recent endeavor – casting one-of-a-kind stone sculpture in bronze.',
        website: 'https://satorotafura.com'
    }

]

class Contractors extends React.Component {
    render(){
        return(
            <div className="bg-white">
                <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                        <div className="space-y-5 sm:space-y-4">
                            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Our Contractors</h2>
                            <p className="text-xl text-gray-500">
                                We only work with the best, our contractors are unique and their work speak for itself.
                            </p>
                        </div>
                        <div className="lg:col-span-2">
                            <ul
                                role="list"
                                className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
                            >
                                {contractors.map((person) => (
                                    <li key={person.name} className="sm:py-8">
                                        <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                                            <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                                <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="space-y-4">
                                                    <div className="text-lg leading-6 font-medium space-y-1">
                                                        <h3>{person.name}</h3>
                                                        <p className="text-indigo-600">{person.role}</p>
                                                    </div>
                                                    <div className="text-lg">
                                                        <p className="text-gray-500">{person.bio}</p>
                                                    </div>
                                                    <ul role="list" className="flex space-x-5">
                                                        <li>
                                                            <a href={person.website} className="text-gray-400 hover:text-gray-500">
                                                                <span className="sr-only">Website</span>
                                                                {person.website}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Contractors;