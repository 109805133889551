import React from "react";
import {Link} from "react-router-dom";

import CristineRi from 'images/testimonials/cristine_ri.jpg';
import DianeFred from 'images/testimonials/diane_fred.jpg';
import KelleyCameron from 'images/testimonials/kelley_cameron.jpg';

const testimonials = [
    { id: 1, name: 'Cristine and Ri', image: CristineRi, location: "Longmont", content: "Anhyka has given me design advice for a number of years on small projects on our property.  When it finally came time to tackle our very large landscaping project as part of our whole house remodel, we naturally turned to Anhyka again.  She was able to generate plans that zeroed right in on how we wanted our landscaping to be integrated with how we used our house and the land.  We have rural property with views and wanted to be invited to interact with the close-in landscaping in ways that connected landscape to the house interior while also allowing the eye to experience the immediate landscaping before it then shifted to the mountain, prairie, and wetland views.  Anhyka captured all of that … beautifully.  We have lovely, inviting, user-friendly spaces for ourselves, our guests, our pets, and our wildlife, and we also have spectacular views that feel as if they are an extension of our own personal landscaping.  Anhyka listened carefully to any concern we mentioned, did extensive research on specific bird-friendly plantings in addition to deer and rabbit-resistant plants, and helped us incorporate a wonderful water feature that is much loved by us and the wildlife.  Her knowledge is vast, her attention to detail is flawless, her ability to stay on task is admirable, and her overall presence is a delight.  She and contractor Telo, with Monte Vista Landscapes, work seamlessly together, and we wouldn’t hesitate to use them again or recommend them to anyone with any size of landscape design work." },
    { id: 2, name: 'Diane and Fred', image: DianeFred, location: "Boulder, 2018", content: "One often hears the adage, \"It's the journey, not the destination that counts.\" In working with Anhyka, as she designed the new landscape for our home, I would have to adjust that just a bit to say, \"It's the journey AND the destination that count.\"\n" +
            "\n" +
            "Anhyka was an absolute delight to work with throughout the design process. Ours was not an easy project, having landscape challenges that pushed the plans to incorporate some stunningly creative results. Anhyka maintains a rare connection and sensitivity to her clients and \"gets\" who they are, and what they are after. Inviting me to join in the boulder and large plant selection was perfect. I loved being more intimately involved with the process, while also feeling quite confidant that the end results would have been wonderful either way. Anhyka also had a remarkable working relationship with the landscape installers and the communication between the two firms appeared seamless. Watching her visions unfold day by day and the ultimate result - the 'destination' - was captivating. The inviting path to our front door is now an experience rather than just a walk, and sitting at our dining table gazing out the window with background sound of trickling water is, well... Zen. We now have an outstanding outdoor environment that we can appreciation from inside and out. Thank you, Anhyka!\n" +
            "\n" +
            "Our local wildlife evidentially enjoys our backyard design too!\n" +
            "\n" +
            "And more recently...\n" +
            "After working on our place, Anhyka did her magic on our son’s home as well, carefully taking into consideration the difference in house style and tastes of our son, resulting in a very different but equally gorgeous landscape. She is currently in the process of pulling together designs for my brother’s house here on the front range, and we can’t wait to see the outcome of this latest project!" },
    { id: 3, name: 'Kelley and Cameron', image: KelleyCameron, location: "Boulder, 2018", content: "We have been thrilled to work with Stoneleaf Design on the creation of our dream front and backyards.  Anhyka is so talented and also incredibly organized, efficient, and professional.  We were amazed by her initial layout concepts and then by how quickly she mobilized to generate estimates and ultimately get the plants in the ground and looking wonderful.  Neighbors (and strangers) stop us on the street to remark how lovely our yard looks - and we agree!  We cannot recommend Stoneleaf Design highly enough." },
]

class Testimonial extends React.Component {
    render(){
        return(
            <div className="relative bg-white py-16 sm:py-24 lg:py-32">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Testimonials</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                        What Our clients says about our work
                    </p>
                    {testimonials.map((testimonal) => (
                        <div key={testimonal.id} className="mt-12">
                            <div className="bg-white pt-16 lg:py-24">
                                <div className="pb-16 bg-gradient-to-r from-yellow-400 via-green-500 to-pink-500 lg:pb-0 lg:z-10 lg:relative">
                                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-8">
                                        <div className="relative lg:-my-8">
                                            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                                            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                                                <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                                                    <img
                                                        className="object-cover lg:h-full lg:w-full"
                                                        src={testimonal.image}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-12 lg:m-0 lg:pl-8">
                                            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                                                <blockquote>
                                                    <div>
                                                        <svg
                                                            className="h-12 w-12 text-white opacity-25"
                                                            fill="currentColor"
                                                            viewBox="0 0 32 32"
                                                            aria-hidden="true"
                                                        >
                                                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                        </svg>
                                                        <p className="mt-6 text-base font-medium text-white">
                                                            {testimonal.content}
                                                        </p>
                                                    </div>
                                                    <footer className="mt-6">
                                                        <p className="text-base font-medium text-white">{testimonal.name}</p>
                                                        <p className="text-base font-medium text-indigo-100">{testimonal.location}</p>
                                                    </footer>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
export default Testimonial;