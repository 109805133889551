import React from "react";
import {Link} from "react-router-dom";
import Image1 from 'images/gallery/gallery_01.jpg';
import Image2 from 'images/gallery/gallery_02.jpg';
import Image3 from 'images/gallery/gallery_03.jpg';
import Image4 from 'images/gallery/gallery_04.jpg';
import Image5 from 'images/gallery/gallery_05.jpg';
import Image6 from 'images/gallery/gallery_06.jpg';
import Image7 from 'images/gallery/gallery_07.jpg';
import Image8 from 'images/gallery/gallery_08.jpg';
import Image9 from 'images/gallery/gallery_09.jpg';
import Image10 from 'images/gallery/gallery_10.jpg';
import Image11 from 'images/gallery/gallery_11.jpg';
import Image12 from 'images/gallery/gallery_12.jpg';
import Image13 from 'images/gallery/gallery_13.jpg';
import Image14 from 'images/gallery/gallery_14.jpg';
import Image15 from 'images/gallery/gallery_15.jpg';
import Image16 from 'images/gallery/gallery_16.jpg';
import Image17 from 'images/gallery/gallery_17.jpg';
import Image18 from 'images/gallery/gallery_18.jpg';
import Image19 from 'images/gallery/gallery_19.jpg';
import Image20 from 'images/gallery/gallery_20.jpg';
import Image21 from 'images/gallery/gallery_21.jpg';
import Image22 from 'images/gallery/gallery_22.jpg';
import Image23 from 'images/gallery/gallery_23.jpg';
import Image24 from 'images/gallery/gallery_24.jpg';
import Image25 from 'images/gallery/gallery_25.jpg';
import Image26 from 'images/gallery/gallery_26.jpg';
import Image27 from 'images/gallery/gallery_27.jpg';
import Image28 from 'images/gallery/gallery_28.jpg';
import Image29 from 'images/gallery/gallery_29.jpg';
import Image30 from 'images/gallery/gallery_30.jpg';
import Image31 from 'images/gallery/gallery_31.jpg';
import Image32 from 'images/gallery/gallery_32.jpg';
import Image33 from 'images/gallery/gallery_33.jpg';
import Image34 from 'images/gallery/gallery_34.jpg';
import Image35 from 'images/gallery/gallery_35.jpg';

const images = [{id: 1, image: Image1}, {id: 2, image: Image2}, {id: 3, image: Image3}, {id: 4, image: Image4}, {id: 5, image: Image5}, {id: 6, image: Image6}, {id: 7, image: Image7}, {id: 8, image: Image8}, {id: 9, image: Image9}, {id: 10, image: Image10}, {id: 11, image: Image11}, {id: 12, image: Image12}, {id: 13, image: Image13}, {id: 14, image: Image14}, {id: 15, image: Image15}, {id: 16, image: Image16}, {id: 17, image: Image17}, {id: 18, image: Image18}, {id: 19, image: Image19}, {id: 20, image: Image20}, {id: 21, image: Image21}, {id: 22, image: Image22}, {id: 23, image: Image23}, {id: 24, image: Image24}, {id: 25, image: Image25}, {id: 26, image: Image26}, {id: 27, image: Image27}, {id: 28, image: Image28}, {id: 29, image: Image29}, {id: 30, image: Image30}, {id: 31, image: Image31}, {id: 32, image: Image32}, {id: 33, image: Image33}, {id: 34, image: Image34}, {id: 35, image: Image35}];

class Gallery extends React.Component {
    render(){
        return(
            <div className="relative bg-white py-16 sm:py-24 lg:py-32">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Our Gallery</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                        Explore some of our amazing collections from our clientele
                    </p>
                    <div className="mt-12">
                        <div className="bg-white">
                            <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                                <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                                    {images.map((item) => (
                                        <Link key={item.id} to="#" className="group">
                                            <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-3">
                                                <img
                                                    src={item.image}
                                                    alt={"Gallery Image " + item.id}
                                                    className="w-full h-full object-center object-cover group-hover:opacity-75"
                                                />
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Gallery;