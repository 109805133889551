import React from "react";
import {Link} from "react-router-dom";
import {
    CloudUploadIcon,
    CogIcon,
    LockClosedIcon,
    RefreshIcon,
    ServerIcon,
    ShieldCheckIcon,
} from '@heroicons/react/outline'

const features = [
    { name: 'On-Site Meetings', icon: CloudUploadIcon, content: "An initial on-site meeting is imperative from a designer’s perspective, as it allows one to get an experiential feel of the property that a stack of plans does not always adequately relay. Subsequent on-site meetings allow me to verify that client/designer communication is optimal and that the garden installation is being executed as envisioned." },
    { name: 'Master Planning', icon: LockClosedIcon, content: "An overall conceptual layout for larger properties, revolving around functions, elements, circulation, and budget allows for the potential phasing of a complex design." },
    { name: 'Conceptual Planning', icon: RefreshIcon, content: "Quick vignettes, blocking out form, function, and proportion allows for exploring multiple potentials lightly before heading into the more intricate fleshing out of a design that happens in the design development process." },
    { name: 'Site-Appropriate Plant Palette', icon: CogIcon, content: "Like any artist’s palette, we will select, together, an array of plant material that suits both the environment of your particular garden and your personal taste and go a step further to gear the palette towards low water-use should you be heading towards a more water conscious lifestyle." },
    { name: 'Vision-Appropriate Material Palette', icon: ServerIcon, content: "Client and designer will decide upon an assortment of materials for patios, seating walls, pools and the like that will best suit both your existing climate and the aesthetic style of your home. If clients are unfamiliar with typical landscape materials, StoneLeaf Design will provide images, links, or samples that will assist clients in making their material choices." },
    { name: 'Budget Determination', icon: ShieldCheckIcon, content: "Once an initial concept is agreed upon, in-house cost projections allow us to attain the clear sense that we are designing within the client’s desired budget." },
    { name: 'Approvals Process', icon: ShieldCheckIcon, content: "StoneLeaf Design is available to cover the requirements of any project’s necessary review committees including presentations, when required, to Homeowners Associations (HOA), Architectural Review Committees (ARC), Design Review Committees (DRC), District Boards, and City or County Building and Development Departments." },
    { name: 'Grading Plans', icon: ShieldCheckIcon, content: "StoneLeaf Design can execute a grading plan to adequately usher surface drainage away from your home’s foundation in an aesthetically pleasing manner. A licensed civil engineer would be brought on board for any complex sub-surface drainage necessary to your project." },
    { name: 'Material Plans', icon: ShieldCheckIcon, content: "This plan calls out the various allocations of certain materials throughout the site." },
    { name: 'Planting Plans', icon: ShieldCheckIcon, content: "A planting plan acts as a reference document to facilitate plant layout and future plant identification for the client." },
    { name: 'Lighting Plans', icon: ShieldCheckIcon, content: "The lighting plan communicates the fixture type, finish, and location throughout your garden depending on the client’s preferred lighting style." },
    { name: 'Construction Documentation', icon: ShieldCheckIcon, content: "The detailed design package from which the landscape contractor works includes a grading plan, materials plan, lighting plan, and planting plan along with the construction details as the landscape contractor or City deems necessary." },
    { name: 'Contractor Selection', icon: ShieldCheckIcon, content: "In the case that clients do not already have their preferred contractors in line, StoneLeaf Design can either introduce clients to a handful of contractors or pair them according to the particular design’s scope of work." },
    { name: 'In-Person Boulder Selection', icon: ShieldCheckIcon, content: "Boulder material is hand-selected from either local stone yards or open ranches in Wyoming to capture the intended aesthetic value. This part of the process can be exciting for clients to join." },
    { name: 'On-Site Direction of Boulder Setting', icon: ShieldCheckIcon, content: "StoneLeaf Design arrives on-site to oversee all boulder placement to ensure that the full artistic value of each boulder is achieved." },
    { name: 'In-Person Tree Selection', icon: ShieldCheckIcon, content: " Large deciduous and evergreen tree specimen are hand-selected for the most pleasing form and overall presence from local nurseries. This part of the process can be exciting for clients to join." },
    { name: 'On-Site Direction of Plant Layout', icon: ShieldCheckIcon, content: "To insure the fullest expression of the intended planting vision, StoneLeaf Design works with the crews of selected landscape contractors to literally place all plant material from the largest of trees to the tiniest of ground covers." },
    { name: 'Construction Administration', icon: ShieldCheckIcon, content: "Throughout the physical installation of your newly imagined garden, StoneLeaf Design closely follows the work of the various contractors at pertinent junctures of the installation process including landscape, boulder, and plant layout." },
    { name: 'Process Check-Ins', icon: ShieldCheckIcon, content: "Subsequent on-site meetings with client, contractor, or both allow me to verify that client/designer communication is optimal and that the garden installation is being executed as envisioned." },
    { name: 'Hourly Consultations', icon: ShieldCheckIcon, content: "On the occasion that a client is simply looking for a boost of inspiration when it comes to their garden, direction can be provided on an hourly consultation basis." },
]

class Services extends React.Component {
    render(){
        return(
            <div className="relative bg-white py-16 sm:py-24 lg:py-32">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Our Services</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                        You decide and we make it a dream
                    </p>
                    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                       Service Descriptions - FAQ
                    </p>
                    <div className="mt-12">
                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            {features.map((feature) => (
                                <div key={feature.name} className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                {feature.content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Services;