/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Component } from "react";
import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid'
import HomeImage1 from 'images/feature/gallery1.jpg';
import HomeImage2 from 'images/feature/gallery2.jpg';
import HomeImage3 from 'images/feature/gallery3.jpg';
import HomeImage4 from 'images/feature/gallery4.jpg';
import HomeLogo from 'images/logo.png';
import MonteVista from 'images/Montevista color logo.png';

const stats = [
    { label: 'Founded', value: '2018' },
    { label: 'Employees', value: '5' },
    { label: 'Customers', value: '100+' },
]
const logos = [
    { name: 'Monte Vista', url: MonteVista }
]

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [HomeImage1, HomeImage3, HomeImage4],
            index: 0,
        };
        this.timoutId = null
        this.intervalId = null
    }
    componentDidMount() {
        this.intervalId = setInterval(() => {
            this.setState(prevState => ({ index: prevState.index + 1 }))
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render(){
        const { index } = this.state
        const img = this.state.images[index % 3]
        return (
            <div className="bg-white">
                <main>
                    {/* Hero section */}
                    <div className="pt-2 overflow-hidden sm:pt-6 lg:relative lg:py-32">
                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                            <div>
                                <div className="mt-4">
                                    <div className="mt-6 sm:max-w-xl">
                                        <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                            <img
                                                className="h-28 w-auto"
                                                src={HomeLogo}
                                                alt="StoneLeaf Design"
                                            />
                                        </h1>
                                        <p className="mt-6 text-xl text-gray-500">
                                            StoneLeaf Design is here to help you augment the living quality of your outdoor spaces so that you can benefit from the entirety of your property and not just the interior spaces of your home. To achieve a fluidity of use between the inside and outside of your home can even expand into maximizing one’s own wellbeing.
                                        </p>
                                    </div>
                                    <form action="#" className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                                        <div className="min-w-0 flex-1">
                                            <label htmlFor="hero-email" className="sr-only">
                                                Email address
                                            </label>
                                            <input
                                                id="hero-email"
                                                type="email"
                                                className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:ml-3">
                                            <button
                                                type="submit"
                                                className="block w-full rounded-md border border-transparent px-5 py-3 bg-rose-500 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
                                            >
                                                Notify me
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-6">
                                        <div className="inline-flex items-center divide-x divide-gray-300">
                                            <div className="flex-shrink-0 flex pr-5">
                                                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            </div>
                                            <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                                                <span className="font-medium text-gray-900">Rated 5 stars</span> by over{' '}
                                                <span className="font-medium text-rose-500">100 customers</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                                <div className="hidden sm:block">
                                    <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                                    <svg
                                        className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                                        width={404}
                                        height={392}
                                        fill="none"
                                        viewBox="0 0 404 392"
                                    >
                                        <defs>
                                            <pattern
                                                id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                                                x={0}
                                                y={0}
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                            </pattern>
                                        </defs>
                                        <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                                    </svg>
                                </div>
                                <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-4">
                                    <img
                                        className="transition ease-out duration-500 w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                                        src={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Testimonial/stats section */}
                    <div className="relative mt-20">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="relative sm:py-16 lg:py-0">
                                <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                    <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                                    <svg
                                        className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                        width={404}
                                        height={392}
                                        fill="none"
                                        viewBox="0 0 404 392"
                                    >
                                        <defs>
                                            <pattern
                                                id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                                x={0}
                                                y={0}
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                            </pattern>
                                        </defs>
                                        <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                    </svg>
                                </div>
                                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                    {/* Testimonial card*/}
                                    <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                        <img
                                            className="absolute inset-0 h-full w-full object-cover"
                                            src={HomeImage2}
                                            alt=""
                                        />
                                        <div className="absolute inset-0 bg-green-500 mix-blend-multiply" />
                                        <div className="absolute inset-0 bg-gradient-to-t from-green-600 via-green-600 opacity-90" />
                                        <div className="relative px-8">
                                            <div>
                                                <img
                                                    className="h-24"
                                                    src={HomeLogo}
                                                    alt=""
                                                />
                                            </div>
                                            <blockquote className="mt-8">
                                                <div className="relative text-lg font-medium text-white md:flex-grow">
                                                    <svg
                                                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-green-400"
                                                        fill="currentColor"
                                                        viewBox="0 0 32 32"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                    </svg>
                                                    <p className="relative">
                                                        We have been thrilled to work with Stoneleaf Design on the creation of our dream front and backyards.  Anhyka is so talented and also incredibly organized, efficient, and professional.  We were amazed by her initial layout concepts and then by how quickly she mobilized to generate estimates and ultimately get the plants in the ground and looking wonderful.  Neighbors (and strangers) stop us on the street to remark how lovely our yard looks - and we agree!  We cannot recommend Stoneleaf Design highly enough.
                                                    </p>
                                                </div>

                                                <footer className="mt-4">
                                                    <p className="text-base font-semibold text-green-200">Kelley and Cameron Testimonial, Boulder, 2018</p>
                                                </footer>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                                {/* Content area */}
                                <div className="pt-12 sm:pt-16 lg:pt-20">
                                    <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                                        Our Values
                                    </h2>
                                    <div className="mt-6 text-gray-500 space-y-6">
                                        <p className="text-lg">
                                            The residential garden design process is as intimate as working with an architect
                                            on the design of your home. Together, we continually consider and prioritize each garden element through every step of the journey to help us land upon the proper flow and balance of the garden experience.
                                        </p>
                                        <p className="text-base leading-7">
                                            StoneLeaf Design will follow your project through the end of its installation,
                                            but a second key to an astounding garden experience is its upkeep. Throughout the last decade, StoneLeaf Design has nourished various relationships with diverse contractors in the field whom are now considered family. This extended family not only would be installing your personal garden but would also be vital to its continuance.
                                        </p>
                                    </div>
                                </div>

                                {/* Stats section */}
                                <div className="mt-10">
                                    <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                                        {stats.map((stat) => (
                                            <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                                                <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                                                <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Logo cloud section */}
                    <div className="mt-32">
                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                            <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                                <div>
                                    <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                        For All of Your Garden’s Needs
                                    </h2>
                                    <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                                        StoneLeaf Design can assist you in uncovering all of your desired elements and functions for your garden. Once a program is unveiled, the design fire is already well-stoked.
                                    </p>
                                    <div className="mt-6">
                                        <a href="#" className="text-base font-medium text-rose-500">
                                            Meet our contractors &nbsp; &rarr;
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                                    {logos.map((logo) => (
                                        <div key={logo.name} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img className="" src={logo.url} alt={logo.name} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CTA section */}
                    <div className="relative mt-24 sm:mt-32 sm:py-16">
                        <div aria-hidden="true" className="hidden sm:block">
                            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                            <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern
                                        id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                            </svg>
                        </div>
                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="relative rounded-2xl px-6 py-10 bg-rose-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                                <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                                    <svg
                                        className="absolute inset-0 h-full w-full"
                                        preserveAspectRatio="xMidYMid slice"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 1463 360"
                                    >
                                        <path
                                            className="text-rose-400 text-opacity-40"
                                            fill="currentColor"
                                            d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                        />
                                        <path
                                            className="text-rose-600 text-opacity-40"
                                            fill="currentColor"
                                            d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                        />
                                    </svg>
                                </div>
                                <div className="relative">
                                    <div className="sm:text-center">
                                        <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                            Give StoneLeaf Design a call today and let’s see <br/> what dream can unfold for you.
                                        </h2>
                                    </div>
                                    <form action="#" className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                                        <div className="min-w-0 flex-1">
                                            <label htmlFor="cta-email" className="sr-only">
                                                Email address
                                            </label>
                                            <input
                                                id="cta-email"
                                                type="email"
                                                className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:ml-3">
                                            <button
                                                type="submit"
                                                className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
                                            >
                                                Notify me
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}
export default Home;
