import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Logo from 'images/logo3.png';
import MyLink from './MyLink'

class Header extends Component {
    render() {
        const navigation = [
            { name: 'Home', href: '/' },
            { name: 'About', href: '/about-us' },
            { name: 'Services', href: '/services' },
            { name: 'Testimonials', href: '/testimonials' },
            { name: 'Contractors', href: '/contractors' },
            { name: 'Gallery', href: '/gallery' },
            { name: 'Contact Us', href: '/contact-us' },
        ]
        return (
            <div className="relative bg-white">
                <div className="relative pt-4 pb-6 sm:pb-15 lg:pb-8">
                    <Popover>
                        <nav
                            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                            aria-label="Global"
                        >
                            <div className="flex items-center flex-1">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <Link to="/">
                                        <span className="sr-only">Workflow</span>
                                        <img
                                            className="h-8 w-auto sm:h-10"
                                            src={Logo}
                                            alt="StoneLeaf Design"
                                        />
                                    </Link>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Open main menu</span>
                                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="hidden md:block md:ml-10 md:space-x-10">
                                    {navigation.map((item) => (
                                        <Link key={item.name} to={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </nav>

                        <Transition
                            as={Fragment}
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="px-5 pt-4 flex items-center justify-between">
                                        <div>
                                            <img
                                                className="h-8 w-auto"
                                                src={Logo}
                                                alt="StoneLeaf Design"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close main menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="px-2 pt-2 pb-3 space-y-1">
                                        {navigation.map((item) => (
                                            <Popover.Button key={item.name} name={item.name} as={MyLink} url={item.href}>
                                                {item.name}
                                            </Popover.Button>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                </div>
            </div>
        )
    }
}

export default Header;