import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./layout/Footer";
import FindUs from "./pages/FindUs";
import Header from "./layout/Header";
import Services from "./pages/Services";
import Testimonial from "./pages/Testimonial";
import Gallery from "./pages/Gallery";
import Contractors from "./pages/Contractors";
import About from "./pages/About";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="relative min-h-screen bg-white min-h-screen">
                    <Header />
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/about-us" exact component={About} />
                        <Route path="/services" exact component={Services} />
                        <Route path="/testimonials" exact component={Testimonial} />
                        <Route path="/contractors" exact component={Contractors} />
                        <Route path="/gallery" exact component={Gallery} />
                        <Route path="/contact-us" exact component={FindUs} />
                    </Switch>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default App;