import React from "react";
import axios from "axios"
import {Link} from "react-router-dom";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import ContactusImage from 'images/feature/contact_image.jpg';
import {
    PhoneIcon,
    MailIcon
} from '@heroicons/react/outline'

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: 40.1657671, lng: -105.1203451 }}
    >
        <Marker
            position={{ lat: 40.1657671, lng: -105.1203451 }}
        />
    </GoogleMap>
));

const elements = [
    { id: 1, label: 'Pool'},
    { id: 2, label: 'Spa'},
    { id: 3, label: 'Water Feature'},
    { id: 4, label: 'Fire Pit'},
    { id: 5, label: 'Pergola'},
    { id: 6, label: 'Patio'},
    { id: 7, label: 'Meditation Garden'},
    { id: 8, label: 'Rose Garden'},
    { id: 9, label: 'Cut Garden'},
    { id: 10, label: 'Vegetable Garden'},
    { id: 11, label: 'Orchard'},
    { id: 12, label: 'Bocce Court'},
    { id: 13, label: 'Sports Lawn'},
    { id: 14, label: 'Formal Design'},
    { id: 15, label: 'Informal Design'},
    { id: 16, label: 'Xeriscape'},
    { id: 17, label: 'Low Maintenance' }
]

class FindUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            budget: '',
            elements: {}
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        axios({
            method: "POST",
            url:"/contacts",
            data:  this.state
        }).then((response)=>{
            if (response.data.message === 'Message Delivered Successfully') {
                this.resetForm()
                window.location.reload(false);
            } else {
                alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        this.setState({name: "", email: "", phone: "", message: "", budget: ""})
    }

    render(){
        return(
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="relative bg-white">
                    <div className="absolute inset-0">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
                    </div>
                    <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                            <div className="max-w-lg mx-auto mb-10">
                                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                    Get in touch
                                </h2>
                            </div>
                            <dl className="mt-8 text-base text-gray-500">
                                <div>
                                    <dt className="sr-only">Office Location</dt>
                                    <dd>
                                        <p>1701 Short Pl</p>
                                        <p>Longmont, CO 80501</p>
                                    </dd>
                                </div>
                                <div className="mt-6">
                                    <dt className="sr-only">Phone number</dt>
                                    <dd className="flex">
                                        <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <span className="ml-3">+1 (732) 597-3334</span>
                                    </dd>
                                </div>
                            </dl>
                            <div className="max-w-lg mx-auto mt-6">
                                <MapWithAMarker
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBcGGblKNWjUz8NHBi2eRlL8gsj2oAlYqU&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </div>
                        </div>
                        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                            <div className="overflow-hidden rounded-lg mb-5">
                                <div className="px-4 py-5 sm:p-6">
                                    <img className="object-cover shadow-lg rounded-lg" src={ContactusImage} alt="Contact Us"/>
                                </div>
                            </div>
                            <div className="max-w-lg mx-auto lg:max-w-none">
                                <form onSubmit={this.handleSubmit.bind(this)} method="POST" className="grid grid-cols-1 gap-y-6">
                                    <div>
                                        <label htmlFor="full_name" className="sr-only">Full name</label>
                                        <input type="text" name="name" id="full_name" autoComplete="name"
                                               className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                               placeholder="Full name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="sr-only">Email</label>
                                        <input id="email" name="email" type="email" autoComplete="email"
                                               className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                               placeholder="Email" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                    </div>
                                    <div>
                                        <label htmlFor="phone" className="sr-only">Phone</label>
                                        <input type="text" name="phone" id="phone" autoComplete="tel"
                                               className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                               placeholder="Phone" value={this.state.phone} onChange={this.onPhoneChange.bind(this)}/>
                                    </div>
                                    <div>
                                        <fieldset className="sm:col-span-2">
                                            <legend className="block text-sm font-medium text-gray-700">Construction budget</legend>
                                            <div className="mt-4 grid grid-cols-1 gap-y-4">
                                                <div className="flex items-center">
                                                    <input
                                                        id="budget-under-10k"
                                                        name="budget"
                                                        defaultValue="under_10k"
                                                        type="radio"
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        onChange={this.onBudgetChange.bind(this)}
                                                    />
                                                    <label htmlFor="budget-under-25k" className="ml-3">
                                                        <span className="block text-sm text-gray-700">Less than $10K</span>
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        id="budget-10k-50k"
                                                        name="budget"
                                                        defaultValue="10k-50k"
                                                        type="radio"
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        onChange={this.onBudgetChange.bind(this)}
                                                    />
                                                    <label htmlFor="budget-25k-50k" className="ml-3">
                                                        <span className="block text-sm text-gray-700">$10K – $50K</span>
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        id="budget-50k-100k"
                                                        name="budget"
                                                        defaultValue="50k-100k"
                                                        type="radio"
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        onChange={this.onBudgetChange.bind(this)}
                                                    />
                                                    <label htmlFor="budget-50k-100k" className="ml-3">
                                                        <span className="block text-sm text-gray-700">$50K – $100K</span>
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        id="budget-over-100k"
                                                        name="budget"
                                                        defaultValue="over_100k"
                                                        type="radio"
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        onChange={this.onBudgetChange.bind(this)}
                                                    />
                                                    <label htmlFor="budget-over-100k" className="ml-3">
                                                        <span className="block text-sm text-gray-700">$100K+</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div>
                                        <fieldset>
                                            <legend className="text-lg font-medium text-gray-900">Select Elements</legend>
                                            <div className="grid grid-cols-2 md:auto-cols-min gap-2 mt-4">
                                                {elements.map((element, personIdx) => (
                                                    <div key={personIdx} className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id={`element-${element.id}`}
                                                                name={element.label}
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                                onChange={this.onElementChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor={`element-${element.id}`} className="font-medium text-gray-700">
                                                                {element.label}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="sr-only">Message</label>
                                        <textarea id="message" name="message" rows="4"
                                                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                  placeholder="Message" value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
                                    </div>
                                    <div>
                                        <button type="submit"
                                                className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onPhoneChange(event) {
        this.setState({phone: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }

    onBudgetChange(event) {
        this.setState({budget: event.target.value})
    }

    onElementChange(event) {
        let obj = {}
        obj[event.target.name] = event.target.value
        this.setState({
            elements: Object.assign({}, this.state.elements,
                obj
            ),
        });
    }
}
export default FindUs;